import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
//import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AlertComponent } from './modules/alert/alert.component';
import { HttpClientModule,HTTP_INTERCEPTORS } from "@angular/common/http";
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { fakeBackendProvider } from './helpers/fake-backend';
import { NgxUiLoaderModule, NgxUiLoaderHttpModule } from  'ngx-ui-loader';
import { ProAthletesComponent } from './modules/pro-athletes/pro-athletes.component';
import { MaterialModule } from './material-module';
import { LayoutModule } from './layout/layout.module';
import { ReportRegularUsersComponent } from './modules/report-regular-users/report-regular-users.component';
import { ChartsModule } from 'ng2-charts';
import { DatePipe } from '@angular/common';
//import { GalleryComponent } from './modules/gallery/gallery.component';


@NgModule({
  declarations: [
    AppComponent,
    AlertComponent,ProAthletesComponent, ReportRegularUsersComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxUiLoaderModule, MaterialModule,LayoutModule,
    ChartsModule,
    //NgxUiLoaderHttpModule
    NgxUiLoaderHttpModule.forRoot({ showForeground: true })
  ],
  providers: [ { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    //{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }



