import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user';
import { environment } from '../../environments/environment'
import { from } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  getAll(page: number, perpage: number, sortdirect: string, type: string,searchstr:string,field:string) {
    let postData = { "page": page, "perpage": perpage, "sortdirect": sortdirect, "type": type,"search": searchstr,'field':field}

    return this.http.post<any>(`${environment.apiUrl}/admin/user-list`, postData);
  }

  getAllUserSatat(page: number, perpage: number, sortdirect: string, type: string) {
    let postData = { "page": page, "perpage": perpage, "sortdirect": sortdirect, "type": type }
    return this.http.post<any>(`${environment.apiUrl}/admin/user-stat-list`, postData);
  }

  getUserGraph(userId, gTime, gType) {
    let postData = { "userId": userId, "gTime": gTime.toString(), "gType": gType.toString() }
    return this.http.post<any>(`${environment.apiUrl}/admin/user-stat-graph`, postData);
  }


  getFaqList(page: number, perpage: number, sortdirect: string, type: string) {
    let postData = { "page": page, "perpage": perpage, "sortdirect": sortdirect, "type": type }
    return this.http.post<any>(`${environment.apiUrl}/admin/faq-list`, postData);
  }

  getGalleryList(page: number, perpage: number, sortdirect: string, type: string) {
    let postData = { "page": page, "perpage": perpage, "sortdirect": sortdirect, "type": type }
    return this.http.post<any>(`${environment.apiUrl}/admin/gallery-list`, postData);
  }

  getFeedbackList(page: number, perpage: number, sortdirect: string, type: string) {
    let postData = { "page": page, "perpage": perpage, "sortdirect": sortdirect, "type": type }
    return this.http.post<any>(`${environment.apiUrl}/admin/feedback-list`, postData);
  }

  getMentorRequestList(page: number, perpage: number, sortdirect: string, type: string,search:string) {
    let postData = { "page": page, "perpage": perpage, "sortdirect": sortdirect, "type": type,"search":search }
    return this.http.post<any>(`${environment.apiUrl}/admin/menotor-request-list`, postData);
  }
  userDetails(userId) {
    // let postData = {"userId":userId,"page":1}
    return this.http.get<any>(`${environment.apiUrl}/user/get-user?userId=` + userId);
  }


  getTotalCount(type: string, mediaType: any,searchStr:any) {
    let postData = { "type": type, "mediaType": mediaType,search:searchStr }
    return this.http.post<any>(`${environment.apiUrl}/admin/user-count`, postData);
  }

  getCount(type: string,search:string) {
    let postData = { "type": type,"search":search }
    return this.http.post<any>(`${environment.apiUrl}/admin/total-count`, postData);
  }

  getDashboard() {
    return this.http.get<any>(`${environment.apiUrl}/admin/dashborad-stat`);
  }

  register(user: User) {
    return this.http.post(`${environment.apiUrl}/users/register`, user);
  }

  delete(id: number) {
    return this.http.delete<any>(`${environment.apiUrl}/admin/remove-user/${id}`);
  }

  deleteFaq(id: number) {
    return this.http.delete<any>(`${environment.apiUrl}/admin/faq-delete/${id}`);
  }

  deleteGallery(id: number) {
    return this.http.delete<any>(`${environment.apiUrl}/admin/gallery-delete/${id}`);
  }
  deleteFeedback(id: number) {
    return this.http.delete<any>(`${environment.apiUrl}/admin/feedback-delete/${id}`);
  }

  deleteMentorRequest(id: number) {
    return this.http.delete<any>(`${environment.apiUrl}/admin/menotor-request-delete/${id}`);
  }

  registerFaq(user: User) {
    return this.http.post<any>(`${environment.apiUrl}/admin/faq-save`, user);
  }

  registerGallery(user: User) {
    return this.http.post<any>(`${environment.apiUrl}/admin/gallery-save`, user);
  }

  getRevenue(page: number, perpage: number, sortdirect: string, type: string) {
    let postData = { "page": page, "perpage": perpage, "sortdirect": sortdirect, "type": type }
    return this.http.post<any>(`${environment.apiUrl}/admin/transaction-list`, postData);
  }

  calculateCredits() {
    // let postData = {"userId":userId,"page":1}
    return this.http.get<any>(`${environment.apiUrl}/admin/generate-credit`);
  }


  updatePassword(postdaata) {
    return this.http.patch<any>(`${environment.apiUrl}/admin/update-password`, postdaata);
  }


  updateEmail(postdaata) {
    return this.http.patch<any>(`${environment.apiUrl}/admin/assign-email`, postdaata);
  }

  sendNotification(title, message) {
    let postdaata = { "title": title, "message": message }
    return this.http.post<any>(`${environment.apiUrl}/notification/send-notification`, postdaata);
  }

  updateStatus(postdaata) {
    return this.http.patch<any>(`${environment.apiUrl}/admin/update-user-status`, postdaata);
  }

  uploadImage(postdata) {
    return this.http.post<any>(`${environment.apiUrl}/upload/gallery-image`, postdata);
  }

}
