import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../models/user';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(username, password) {
        console.log(username + "==" + password)
        return this.http.post<any>(`${environment.apiUrl}/admin/login`, { username, password })
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                console.log("=========user.result", user.result)
                if (user.status) {
                    localStorage.setItem('currentUser', JSON.stringify(user.result));
                }
                if (user.result.length == 0) {
                    this.currentUserSubject.next(null);
                } else {
                    this.currentUserSubject.next(user.result);
                }
                return user;

            }));

    }

    logout(userId) {
       localStorage.removeItem('currentUser');
        // remove user from local storage and set current user to null
        //this.currentUserSubject.next(null);
        return this.http.patch<any>(`${environment.apiUrl}/user/logout`, {userId:userId})
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                // console.log("=========user.result", user.result)
                localStorage.removeItem('currentUser');      
                this.currentUserSubject.next(null);
               
                return user;
            }));
    }
}

