import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../../services/authentication.service';
import { User } from '../../models/user';
import { AlertService } from '../../services/alert.service';
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  baseImgUrl: string;
  currentUser: User;
  userData: any;
  userName: any;
  userId: any;

  constructor(private router: Router, private authenticationService: AuthenticationService, private alertService: AlertService) {
    console.log("router", this.router.url);
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    if (this.authenticationService.currentUserValue) {

      this.userData = this.authenticationService.currentUserValue
      this.userName = this.userData.name
      this.userId = this.userData.id
      // console.log("userData",this.userData);         
    }

  }

  showLogoutConfirmation(){
    $("#showLogoutModal").modal('show')
  }

  ngOnInit() {
    this.baseImgUrl = environment.baseInnerpageImgUrl;
    document.body.className = "fixed-nav";

    // scroll to top
    $('#scroll').click(function () {
      $("html, body").animate({ scrollTop: 0 }, 600);
      return false;
    });

    // 

    // $(document).ready(function() {
    //   $('#dataTable').DataTable();
    // });


    // Configure tooltips for collapsed side navigation
    $('.navbar-sidenav [data-toggle="tooltip"]').tooltip({
      template: '<div class="tooltip navbar-sidenav-tooltip" role="tooltip" style="pointer-events: none;"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
    })

    $(".nav-item").click(function (e) {
      e.preventDefault();
      //$(".nav-item").attr('aria-expanded','false')

      //$(".nav-item").attr('aria-expanded','false')
      //$(".navbar-sidenav .sidenav-second-level, .navbar-sidenav .sidenav-third-level").removeClass("show");
      // $(".navbar-sidenav .sidenav-second-level, .navbar-sidenav .sidenav-third-level").removeClass("nav-item");

      //e.addClass('nav-item')

      //$(".nav-item").click()
      console.log(e)
    })
    // Toggle the side navigation
    // $("#sidenavToggler").click(function(e) {
    //   e.preventDefault();
    //   $("body").toggleClass("sidenav-toggled");
    //   $(".navbar-sidenav .nav-link-collapse").addClass("collapsed");
    //   $(".navbar-sidenav .sidenav-second-level, .navbar-sidenav .sidenav-third-level").removeClass("show");
    // });
    // Force the toggled class to be removed when a collapsible nav link is clicked
    // $(".navbar-sidenav .nav-link-collapse").click(function(e) {
    //   e.preventDefault();
    //   $("body").removeClass("sidenav-toggled");
    // });
    // Prevent the content wrapper from scrolling when the fixed side navigation hovered over
    // $('body.fixed-nav .navbar-sidenav, body.fixed-nav .sidenav-toggler, body.fixed-nav .navbar-collapse').on('mousewheel DOMMouseScroll', function(e) {
    //   var e0 = e.originalEvent,
    //     delta = e0.wheelDelta || -e0.detail;
    //   this.scrollTop += (delta < 0 ? 1 : -1) * 30;
    //   e.preventDefault();
    // });
    // Scroll to top button appear
    $(document).scroll(function () {
      var scrollDistance = $(this).scrollTop();
      if (scrollDistance > 100) {
        $('.scroll-to-top').fadeIn();
      } else {
        $('.scroll-to-top').fadeOut();
      }
    });
    // Configure tooltips globally
    $('[data-toggle="tooltip"]').tooltip()
    // Smooth scrolling using jQuery easing
    // $(document).on('click', 'a.scroll-to-top', function(event) {
    //   var $anchor = $(this);
    //   $('html, body').stop().animate({
    //     scrollTop: ($($anchor.attr('href')).offset().top)
    //   }, 1000, 'easeInOutExpo');
    //   event.preventDefault();
    // });
    // 
  }

  logout() {
    // reset alerts on submit
    this.alertService.clear();

    this.authenticationService.logout(this.userId).subscribe();
    this.router.navigate(['/']);
  }

}
