import { Injectable } from '@angular/core';
import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from "rxjs";
import { User } from '../models/user';
import { catchError, finalize } from "rxjs/operators";
import { UserService } from "./user.service";
import { MapOperator } from 'rxjs/internal/operators/map';
import { ContentService } from '../services/content.service'
@Injectable({
  providedIn: 'root'
})
export class DataSourceService {
  private UserSubject = new BehaviorSubject<any>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  constructor(private userService: UserService) { }

  loadUser(type: string, filter: string,
    sortDirection: string,   
    pageIndex: number,
    pageSize: number,seachStr:string,field:string) {
    this.loadingSubject.next(true);
    this.userService.getAll(pageIndex,pageSize,sortDirection,type,seachStr,field).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    )
      .subscribe(data =>{
        console.log(data.result)
        this.UserSubject.next(data.result)
      } );
  }

  loadUserSatat(type: string, filter: string,
    sortDirection: string,   
    pageIndex: number,
    pageSize: number) {
    this.loadingSubject.next(true);
    this.userService.getAllUserSatat(pageIndex,pageSize,sortDirection,type).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    )
      .subscribe(data =>{
        console.log(data.result)
        this.UserSubject.next(data.result)
      } );
  }


  connect(collectionViewer: CollectionViewer): Observable<User[]> {
    return this.UserSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.UserSubject.complete();
    this.loadingSubject.complete();
  }
 
}


export class DataContentService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  private ContentSubject = new BehaviorSubject<any>([]);

  constructor(private contentService:ContentService) { }

  loadContent(type: string, filter: string,
    sortDirection: string,   
    pageIndex: number,
    pageSize: number,mediaId:any,mediaType:any) {
    this.loadingSubject.next(true);
    this.contentService.getAll(pageIndex,pageSize,sortDirection,type,mediaId,mediaType).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    )
      .subscribe(data =>{
        console.log(data.result)
        this.ContentSubject.next(data.result)
      } );
  }

  connect(collectionViewer: CollectionViewer): Observable<User[]> {
    return this.ContentSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.ContentSubject.complete();
    this.loadingSubject.complete();
  }
  
}


export class ReportedContentService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  private ContentSubject = new BehaviorSubject<any>([]);

  constructor(private contentService:ContentService) { }

  loadContent(type: string, filter: string,
    sortDirection: string,   
    pageIndex: number,
    pageSize: number,mediaId:any,mediaType:any) {
    this.loadingSubject.next(true);
    this.contentService.getAllreported(pageIndex,pageSize,sortDirection,type,mediaId,mediaType).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    )
      .subscribe(data =>{
        console.log(data.result)
        this.ContentSubject.next(data.result)
      } );
  }

  connect(collectionViewer: CollectionViewer): Observable<User[]> {
    return this.ContentSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.ContentSubject.complete();
    this.loadingSubject.complete();
  }
  
}


export class FaqDataSourceService {
  private FaqSubject = new BehaviorSubject<any>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  constructor(private userService: UserService) { }

  loadFaq(type: string, filter: string,
    sortDirection: string,   
    pageIndex: number,
    pageSize: number) {
    this.loadingSubject.next(true);
    this.userService.getFaqList(pageIndex,pageSize,sortDirection,type).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    )
      .subscribe(data =>{
        console.log(data.result)
        this.FaqSubject.next(data.result)
      } );
  }

  connect(collectionViewer: CollectionViewer): Observable<User[]> {
    return this.FaqSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.FaqSubject.complete();
    this.loadingSubject.complete();
  }
 
}


export class FeedbackDataSourceService {
  private FeedBackSubject = new BehaviorSubject<any>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  constructor(private userService: UserService) { }

  loadFeedback(type: string, filter: string,
    sortDirection: string,   
    pageIndex: number,
    pageSize: number) {
    this.loadingSubject.next(true);
    this.userService.getFeedbackList(pageIndex,pageSize,sortDirection,type).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    )
      .subscribe(data =>{
        console.log(data.result)
        this.FeedBackSubject.next(data.result)
      } );
  }

  connect(collectionViewer: CollectionViewer): Observable<User[]> {
    return this.FeedBackSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.FeedBackSubject.complete();
    this.loadingSubject.complete();
  }
 
}


export class MentorReqDataSourceService {
  private MentorReqSubject = new BehaviorSubject<any>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  constructor(private userService: UserService) { }

  loadMentorRequest(type: string, filter: string,
    sortDirection: string,   
    pageIndex: number,
    pageSize: number,search:string) {
    this.loadingSubject.next(true);
    this.userService.getMentorRequestList(pageIndex,pageSize,sortDirection,type,search).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    )
      .subscribe(data =>{
        console.log(data.result)
        this.MentorReqSubject.next(data.result)
      } );
  }

  connect(collectionViewer: CollectionViewer): Observable<User[]> {
    return this.MentorReqSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.MentorReqSubject.complete();
    this.loadingSubject.complete();
  }
 
}


export class RevenueDataSourceService {
  private RevenueSubject = new BehaviorSubject<any>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  constructor(private userService: UserService) { }

  loadRevenue(type: string, filter: string,
    sortDirection: string,   
    pageIndex: number,
    pageSize: number) {
    this.loadingSubject.next(true);
    this.userService.getRevenue(pageIndex,pageSize,sortDirection,type).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    )
      .subscribe(data =>{
        console.log(data.result)
        this.RevenueSubject.next(data.result)
      } );
  }

  connect(collectionViewer: CollectionViewer): Observable<User[]> {
    return this.RevenueSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.RevenueSubject.complete();
    this.loadingSubject.complete();
  }
 
}


export class GalleryDataSourceService {
  private gallerySubject = new BehaviorSubject<any>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  constructor(private userService: UserService) { }

  loadGallery(type: string, filter: string,
    sortDirection: string,   
    pageIndex: number,
    pageSize: number) {
    this.loadingSubject.next(true);
    this.userService.getGalleryList(pageIndex,pageSize,sortDirection,type).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    )
      .subscribe(data =>{
        console.log(data.result)
        this.gallerySubject.next(data.result)
      } );
  }

  connect(collectionViewer: CollectionViewer): Observable<User[]> {
    return this.gallerySubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.gallerySubject.complete();
    this.loadingSubject.complete();
  }
 
}