
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { environment } from 'src/environments/environment';
import { SelectionModel } from '@angular/cdk/collections';
import { UserService } from '../../services/user.service'
import { ContentService } from '../../services/content.service'
import { DataSourceService } from "../../services/data-source.service";
import { debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';
import { merge, fromEvent } from "rxjs";
import { AlertService } from '../../services/alert.service';

import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';



declare var $: any;

@Component({
  selector: 'app-report-regular-users',
  templateUrl: './report-regular-users.component.html',
  styleUrls: ['./report-regular-users.component.scss']
})
export class ReportRegularUsersComponent implements OnInit {
  displayedColumns: string[] = ['position','name','tVideos', 'tViews','tFollowers', 'tLikes','tComments','tDislike', 'tDurationVideos', 'tGraph'];
//  dataSource = ELEMENT_DATA;

  dataSource: any
  totalrecord: any = 0
  length: Number = 0;
  pageSize: any = 10
  delRowUserId: any = 0;
  chartTime: { p: string; }[];
  selectedchartTime: number = 0;

 
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  baseImgUrl: string;

  selectGraphType: number = 0;
  GraphLabel: any = ['Total Videos', 'Total Views', 'Total Followers', 'Total Likes', 'Total Comments', 'Total Dislike', 'Total Duration of Videos']
  getGraphUserId:any = 0


  lineChartData: ChartDataSets[] = [
    { data: [85, 72, 78, 75, 77, 75], label: 'Crude oil prices' },
  ];

  lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June'];

  lineChartOptions = {
    responsive: true,
    scales : {
      yAxes: [{
        ticks: {
          precision: 0,
          beginAtZero: true
        }
      }],
      xAxes: [{
  
 
        }],
    },
    bezierCurve: false
  };

  lineChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor:'#D5493F'// 'rgba(255,255,0,0.28)',
    },
  ];

  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = 'line';
  labelName: any;


  
  constructor(private userService: UserService, private contentService: ContentService, private alertService: AlertService) { }

  ngOnInit() {

    this.baseImgUrl = environment.baseImgUrl
    let tempArr = []
    this.userService.getTotalCount('youth', '','').subscribe(data => {
      this.totalrecord = data.result
      this.dataSource = new DataSourceService(this.userService);
      this.dataSource.loadUserSatat('youth', '', this.sort.direction, this.paginator.pageIndex, this.pageSize);

    })

   
    this.chartTime = [
      {p:'All'},
      {p:'Today'},
      {p:'Last 7 Days'},
      {p:'Last 30 Days'},
    ];

  }

  openGraph(userId){
    
    this.getGraphUserId =userId
    this.getGraphData()
    $("#graphModal").modal('show')
  }

  selectchartTime(index: number = 1) {
    this.selectedchartTime = index;
    this.getGraphData()
  }

  selGraphType(e) {
    this.selectGraphType = (e.target.selectedOptions[0].index) - 1
    this.labelName = this.GraphLabel[this.selectGraphType]
    this.getGraphData()
    // this.lineChartData[0].label = this.GraphLabel[e.target.selectedOptions[0].index]
  }

  getGraphData() {
    // 0 -All, 1 -Today, 2-Last 7 days, 3 30 days
    // 0 -Total Videos, 1- Total Views, 2- Total Followers , 3- Total Likes, 4- total Comment , 5 Total Dislike, 6 Total Duration
    this.userService.getUserGraph(this.getGraphUserId, this.selectedchartTime, this.selectGraphType).subscribe(data => {
      if (data.status == true) {

        console.log(data.result.xAxis)
        this.lineChartData = [
          { data: data.result.yAxis, label: this.labelName },
        ];

        this.lineChartLabels = data.result.xAxis;

      }

    })


  }



  deleteRow() {
    if (this.delRowUserId != 0) {
      this.userService.delete(this.delRowUserId).subscribe(data => {
        if (data.status) {
          this.alertService.success("User has been deleted.");
          this.userService.getTotalCount('youth', '','').subscribe(data => {
            this.totalrecord = data.result
            this.dataSource = new DataSourceService(this.userService);
            this.dataSource.loadUserSatat('youth', '', this.sort.direction, this.paginator.pageIndex, this.pageSize);

          })
          setTimeout(() => {
            this.alertService.clear();
          }, 1500);
        } else {
          this.alertService.error("Please try again.");
          setTimeout(() => {
            this.alertService.clear();
          }, 1500);

        }
        $("#DeleteModal").modal('hide');
      });
    }
  }

  removeSelectedRows(index: number, userId: number) {
    this.delRowUserId = userId

    $("#DeleteModal").modal('show');

 
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadLessonsPage())
      )
      .subscribe();

  }

  loadLessonsPage() {
    //this.paginator.pageIndex = this.paginator.pageIndex + 1
    // alert(this.sort.direction)

    this.dataSource.loadUserSatat(
      'youth',
      '',
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

}



export interface PeriodicElement {
  position: number;
  name:any;
  tVideos:any;
  tViews:any;
  tFollowers:any;
  tLikes:any;
  tComments:any;
  tDislike:any;
  tDurationVideos:any;
}
/*
const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1, name: 'Anna K.', tVideos: 354,tViews: 354, tUsers: 233, tFollowers: 44, tLikes:55,tComments:544, tDislike:55, tDurationVideos:44},
  {position: 2, name: 'Anna K.', tVideos: 354,tViews: 354, tUsers: 233, tFollowers: 44, tLikes:55,tComments:544, tDislike:55, tDurationVideos:44},
  {position: 3, name: 'Anna K.', tVideos: 354,tViews: 354, tUsers: 233, tFollowers: 44, tLikes:55,tComments:544, tDislike:55, tDurationVideos:44},
  {position: 4, name: 'Anna K.', tVideos: 354,tViews: 354, tUsers: 233, tFollowers: 44, tLikes:55,tComments:544, tDislike:55, tDurationVideos:44},
  {position: 5, name: 'Anna K.', tVideos: 354,tViews: 354, tUsers: 233, tFollowers: 44, tLikes:55,tComments:544, tDislike:55, tDurationVideos:44},
  {position: 6, name: 'Anna K.', tVideos: 354,tViews: 354, tUsers: 233, tFollowers: 44, tLikes:55,tComments:544, tDislike:55, tDurationVideos:44},
  {position: 7, name: 'Anna K.', tVideos: 354,tViews: 354, tUsers: 233, tFollowers: 44, tLikes:55,tComments:544, tDislike:55, tDurationVideos:44},
];
*/