import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './helpers/auth.guard';
import { ProAthletesComponent } from './modules/pro-athletes/pro-athletes.component';
import { ReportRegularUsersComponent } from './modules/report-regular-users/report-regular-users.component';

const routes: Routes = [
  { path: '',loadChildren: () => import('./modules/landing/landing.module').then(m => m.LandingModule) },
  { path: 'login',loadChildren: () => import('./modules/landing/landing.module').then(m => m.LandingModule) },  
  { path: 'dashboard',loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),canActivate:[AuthGuard] },
  { path: 'main', loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule),canActivate:[AuthGuard] },
  { path: 'mentors-management',loadChildren: () => import('./modules/mentors/mentors.module').then(m => m.MentorsModule),canActivate:[AuthGuard] },
  { path: 'create-profile', loadChildren: () => import('./modules/create-profile/create-profile.module').then(m => m.CreateProfileModule),canActivate:[AuthGuard]},
  { path: 'regular-users', loadChildren: () => import('./modules/regular-users/regular-users.module').then(m => m.RegularUsersModule),canActivate:[AuthGuard] },
  { path: 'content', loadChildren: () => import('./modules/content/content.module').then(m => m.ContentModule),canActivate:[AuthGuard] },
  { path: 'reported-content', loadChildren: () => import('./modules/reported-content/reported-content.module').then(m => m.ReportedContentModule) ,canActivate:[AuthGuard]},
  { path: 'notifications', loadChildren: () => import('./modules/notifications/notifications.module').then(m => m.NotificationsModule),canActivate:[AuthGuard] },
  // { path: 'analytics',canActivate:[AuthGuard], loadChildren: () => import('./modules/analytics/analytics.module').then(m => m.AnalyticsModule) },
  { path: 'report-total-revenue', loadChildren: () => import('./modules/reporting/reporting.module').then(m => m.ReportingModule),canActivate:[AuthGuard]},
  { path: 'report-pro-athletes', component: ProAthletesComponent,canActivate:[AuthGuard]},
  { path: 'report-regular-users', component: ReportRegularUsersComponent,canActivate:[AuthGuard]},
  { path: 'generate-credentials/:id/:pass', loadChildren: () => import('./modules/generate-credentials/generate-credentials.module').then(m => m.GenerateCredentialsModule) ,canActivate:[AuthGuard]},
  { path: 'support-faq', loadChildren: () => import('./modules/faq/faq.module').then(m => m.FaqModule),canActivate:[AuthGuard]},
  { path: 'support-feedback', loadChildren: () => import('./modules/feedback/feedback.module').then(m => m.FeedbackModule),canActivate:[AuthGuard]},
  { path: 'gallery-images', loadChildren: () => import('./modules/gallery/gallery.module').then(m => m.GalleryModule),canActivate:[AuthGuard]},  
  { path: 'mentors-request', loadChildren: () => import('./modules/request/request.module').then(m => m.RequestModule),canActivate:[AuthGuard]},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { 
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    onSameUrlNavigation: "reload",
    preloadingStrategy:PreloadAllModules
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
