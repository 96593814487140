import { Injectable } from '@angular/core';
//import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService,private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = this.authenticationService.currentUserValue;
        // console.log("====================>",currentUser)
        console.log("====================>", request)
        if (currentUser) {
            request = request.clone({
                setHeaders: {
                    Authorization: "Basic " + window.btoa("3xaUser!@3#:9raPass@3!)#@done"),
                    "x-access-token": currentUser.token
                }
            });
        } else {

            request = request.clone({
                setHeaders: {
                    //Authorization: `Bearer ${currentUser.token}`,
                    Authorization: "Basic " + window.btoa("3xaUser!@3#:9raPass@3!)#@done")
                }
            });

        }

        return next.handle(request).pipe(
            /* map((event: HttpEvent<any>) => {
             console.log("event instanceof HttpResponse===",event instanceof HttpResponse)
                 if (event instanceof HttpResponse) {
                     console.log('event--->>>', event);
                 }
                 
                 
                 return event;
 
             })*/

            catchError((error: HttpErrorResponse) => {
                let data = {};
                data = {
                    reason: error && error.error.reason ? error.error.reason : '',
                    status: error.status
                };
                this.router.navigate(['/'])
                //console.log(data,"======================>error-status",error.status)
                // this.errorDialogService.openDialog(data);
                return throwError(error);
            })

        )

        //return next.handle(request).pipe()   


        //return next.handle(request)
    }
}