import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service'
import { AlertService } from '../../services/alert.service';
import { AuthenticationService } from '../../services/authentication.service'

//import { $ } from 'protractor';
declare var $: any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  baseImgUrl: string;
  chartTime:any[];
  selectedchartTime: number = 0;
  resetForm: FormGroup;
  submitted: boolean = false
  currentUser:any = 0
  profile_pic: string = ''
  singalurl: string;
  
  
  constructor(private userService: UserService, private alertService: AlertService, private formBuilder: FormBuilder, private router: Router,private authenticationService: AuthenticationService,
    ) {

      
     }
  get f() { return this.resetForm.controls; }

  ngOnInit() {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    //console.log("checkooooooo=>",this.currentUser.id)
    this.baseImgUrl = environment.baseInnerpageImgUrl;
    this.chartTime = [
      {p:'All'},
      {p:'Today'},
      {p:'Last 7 Days'},
      {p:'Last 30 Days'},
    ];

    this.resetForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmPass: ['', Validators.required]
    },{
            validator: this.MustMatch('password', 'confirmPass')
        });
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
  }

  selectchartTime(index: number=1){
    this.selectedchartTime = index;
  }

  onSubmit() {

    this.submitted = true;
    this.alertService.clear();
    if (this.resetForm.invalid) {
      return false;
    }
    let payoadData = {id:this.currentUser.id,email:this.currentUser.email,password:this.resetForm.value.password} 
    this.userService.updatePassword(payoadData).subscribe(res=>{
      if(res.status == true){
        $("#resetpwmodal").modal('hide')
        this.alertService.success("Password updated successfully.")
        }else{
        this.alertService.error("Password not update. Please try again.")

      }

      setTimeout(() => {
        this.alertService.clear();
      }, 1500);
   })

  }

  



}
