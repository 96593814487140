import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment} from '../../environments/environment'
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor(private http: HttpClient) { }


  getAll(page: number,perpage:number,sortdirect:string,type:string,mediaId:any,mediaType:any) {
    
    let postData ={"page":page,"perpage":perpage,"sortdirect":sortdirect,"type":type,"mediaId":mediaId,"mediaType":mediaType}
    return this.http.post<any>(`${environment.apiUrl}/admin/content-list`,postData);
  }

  getAllreported(page: number,perpage:number,sortdirect:string,type:string,mediaId:any,mediaType:any){
    let postData ={"page":page,"perpage":perpage,"sortdirect":sortdirect,"type":type,"mediaId":mediaId,"mediaType":mediaType}
    return this.http.post<any>(`${environment.apiUrl}/admin/content-list`,postData);
  }

  getContentDetails(mediaId){
    let postData ={"page":1,"perpage":1,"sortdirect":'',"type":'',"mediaId":mediaId,"mediaType":""}
    return this.http.post<any>(`${environment.apiUrl}/admin/content-list`,postData);
  }

  getcomments(mediaId){
    let postData ={"page":1,"perpage":1,"sortdirect":'',"type":'',"mediaId":mediaId}
    return this.http.post<any>(`${environment.apiUrl}/admin/comment-list`,postData);
  }

  getnextcomments(mediaId,page){
    let postData ={"page":page,"perpage":1,"sortdirect":'',"type":'',"mediaId":mediaId}
    return this.http.post<any>(`${environment.apiUrl}/admin/comment-list`,postData);
  }

  delete(id: number) {
    return this.http.delete<any>(`${environment.apiUrl}/admin/remove-media/${id}`);  
  }

}
