import { Component } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';
import { User } from './models/user';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'admin';
  currentUser: User;
  userData: any;
  userName: any;
  userId: any;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    if (this.authenticationService.currentUserValue) {

      this.userData = this.authenticationService.currentUserValue
      this.userName = this.userData.name
      this.userId = this.userData.id
      // console.log("userData",this.userData);         
    }
  }

  logout() {
    this.authenticationService.logout(this.userId).subscribe();
    this.router.navigate(['/login']);
  }


}
